<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('pages.edit-menu') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <MenuForm :menu="menu" />
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import MenuForm from "@/components/admin/page/menu/MenuForm.vue"

export default {
  name: 'EditMenu',
  components: { NavBar, MenuForm },
  data: function() {
    return {
      menu: {},
      menuId: this.$route.params.menuId,
      showOverlay: true
    }
  },
  created: async function() {
    await this.$http.get("/cms/api/page/menu/get/" + this.menuId, { headers: {Authorization: this.getAuthData().accessToken }})
      .then(r => {
        this.menu = r.data;
        // console.log(r);
      }).catch(error => {  this.handleError(error); })
    this.showOverlay = false;
  }
}
</script>
<style></style>
